import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./css/style.css";
import Login from './components/Login/Login';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Layout from './components/Layout';

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, []);

    // Función para verificar si el usuario está autenticado
    const isAuthenticated =  () => {
        return user !== null;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                {/* Rutas protegidas */}
                <Route
                    path="/Inicio"
                    element={<Layout isAuthenticated={isAuthenticated} />}
                />
            </Routes>
        </Router>
    );
}

export default App;
