import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBgiaVdI9YBE3w6GLZrbhbBEpyAgZsQSCA",
  authDomain: "gestion-tienda-dcd4c.firebaseapp.com",
  projectId: "gestion-tienda-dcd4c",
  storageBucket: "gestion-tienda-dcd4c.appspot.com",
  messagingSenderId: "582235266889",
  appId: "1:582235266889:web:2ad2e3723d4524e3ca4498",
  measurementId: "G-JMC8TQF30M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export default app;
