import React, { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import Swal from 'sweetalert2';


const Registro = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nivelUsuario, setNivelUsuario] = useState('vendedor'); // Por defecto, nivel de usuario 'vendedor'
    const [usuarios, setUsuarios] = useState([]);
    const usuariosDb = collection(db, 'usuarios');



    // Función para registrar un nuevo usuario
    const registrarUsuario = useCallback(() => {
        if (!email || !password || !nivelUsuario) {
            console.error("Por favor, complete todos los campos.");
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Guarda la información adicional en la base de datos
                addDoc(usuariosDb, {
                    userId: userCredential.user.uid,
                    email: email,
                    nivelUsuario: nivelUsuario
                })
                    .then(() => {
                        Swal.fire({
                            width: "25em",
                            color: "#fff",
                            background: "#2B2B2B",
                            position: "center",
                            confirmButtonColor:"rgb(0, 160, 223)",
                            title: "Usuario creado con Exito!",
                        })
                        setEmail('');
                        setPassword('');
                        setNivelUsuario('');
                    })
                    .catch((error) => {
                        console.error("Error al registrar usuario en la base de datos:", error.message);
                    });
            })
            .catch((error) => {
                console.error("Error al registrar usuario:", error.message);
            });
    });

    // Función para cargar los usuarios desde la base de datos
    const cargarUsuarios = useCallback(async () => {
        const querySnapshot = await getDocs(usuariosDb);
        const usuariosArray = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Comparar el nivel de usuario para determinar si es admin o vendedor
            let tipoUsuario;
            if (data.nivelUsuario === 'admin') {
                tipoUsuario = 'Admin';
            } else if (data.nivelUsuario === 'vendedor') {
                tipoUsuario = 'Vendedor';
            } else {
                tipoUsuario = 'Desconocido';
            }
            usuariosArray.push({
                id: doc.id,
                email: data.email,
                nivelUsuario: tipoUsuario
            });
        });
        setUsuarios(usuariosArray);
    });

    // Función para cambiar el nivel de usuario
    const cambiarNivelUsuario = useCallback((userId, nuevoNivel) => {
        const usuarioRef = doc(usuariosDb, userId); // Referencia al documento del usuario específico
        updateDoc(usuarioRef, {
            nivelUsuario: nuevoNivel
        })
            .then(() => {
                console.log("Nivel de usuario actualizado correctamente.");
                cargarUsuarios(); // Recargar la lista de usuarios después de actualizar
            })
            .catch(error => {
                console.error("Error al actualizar nivel de usuario:", error.message);
            });
    });

    // Llama a cargarUsuarios cuando el componente se monta
    useEffect(() => {
        cargarUsuarios();
    }, []);

    return (
        <div className='contenedorRegistro'>
            <div className='registro'>
                <form className='formularioLogin' onSubmit={registrarUsuario}>
                    <h2>Registro de Usuarios</h2>
                    <label className='labelLogin'>Email</label>
                    <input className='inputLogin' type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                    <label className='labelLogin'>Contraseña</label>
                    <input className='inputLogin' type="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} />
                    <label className='labelLogin'>Rol de Usuario</label>
                    <select className='selectLogin' value={nivelUsuario} onChange={e => setNivelUsuario(e.target.value)}>
                        <option value="vendedor">Vendedor</option>
                        <option value="admin">Admin</option>
                    </select>
                    <button type="submit" className='btnLogin'>Registrar Usuario</button>

                </form>
            </div>
        </div>
    );
}

export default Registro;
