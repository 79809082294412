import { faEdit, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { db } from '../firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import FirebaseDataFetcher from './Llamada/FirebaseDataFetcher';

const CategoriList = () => {
    const [categoria, setCategoria] = useState([]);
    

    const descargaCategorias= FirebaseDataFetcher({collectionName:'categorias'})

    // Función para cargar los categoria desde la base de datos
    const cargarCategoria = async () => {
        const categoriaArray = [];
        descargaCategorias.forEach((doc) => {
            const data = doc.data();
            console.log(data)
            categoriaArray.push({
                id: doc.id,
                nombre: data.nombre,
            });
        });
        setCategoria(categoriaArray);
    }

    // Función para cambiar el nivel de usuario
    const cambiarNivelCategoria = (id, nuevoNombre) => {
        const categoriaRef = doc(descargaCategorias); // Referencia al documento del categoria específico
        updateDoc(categoriaRef, {
            nombre: nuevoNombre
        })
            .then(() => {
                console.log("Nivel de usuario actualizado correctamente.");
                cargarCategoria(); // Recargar la lista de usuarios después de actualizar
            })
            .catch(error => {
                console.error("Error al actualizar nivel de usuario:", error.message);
            });
    }

    const mostrarFormulario = (id, nombreActual) => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor:"rgb(0, 160, 223)",
            title: 'Actualizar Nombre',
            html:
                `<input id="nombre" class="swal2-input" value="${nombreActual}">`,
            focusConfirm: false,
            preConfirm: () => {
                const nuevoNombre = Swal.getPopup().querySelector('#nombre').value;
                if (!nuevoNombre) {
                    Swal.showValidationMessage('Por favor ingrese un nuevo nombre');
                }
                return { nuevoNombre: nuevoNombre };
            }
        }).then(result => {
            if (result.isConfirmed) {
                cambiarNivelCategoria(id, result.value.nuevoNombre);
            }
        });
    }

    const addCatego = async (catego) => {
        try {
            await addDoc(descargaCategorias, {
                nombre: catego.name,
            }).then(() => {
                cargarCategoria(); // Recargar la lista de usuarios después de actualizar
            });

            console.log('Catego added successfully');
        } catch (e) {
            console.error('Error adding Catego:', e);
        }
    }

    const mostrarFormularioAgregar = () => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor:"rgb(0, 160, 223)",
            title: 'Agregar Nueva Categoría',
            html:
                `<input id="nombre" class="swal2-input" placeholder="Nombre de la categoría">`,
            focusConfirm: false,
            preConfirm: () => {
                const nuevoNombre = Swal.getPopup().querySelector('#nombre').value;
                if (!nuevoNombre) {
                    Swal.showValidationMessage('Por favor ingrese un nombre para la categoría');
                }
                return nuevoNombre;
            }
        }).then(result => {
            if (result.isConfirmed) {
                addCatego({ name: result.value });
            }
        });
    }

    const handleDeleteCatego = async (categoId) => {
        try {
            await deleteDoc(doc(db, 'categorias', categoId));
            console.log('Producto eliminado con ID:', categoId);
            await cargarCategoria();

        } catch (error) {
            console.error('Error al eliminar producto:', error);
        }
    }

    const mostrarAlerta = (categoId) => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor:"rgb(0, 160, 223)",
            title: "Estás seguro de que quieres eliminar esta categoria?",
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            denyButtonText: `Cancelar`
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteCatego(categoId);
            } else if (result.isDenied) {
                Swal.fire({
                    width: "25em",
                    color: "#fff",
                    background: "#2B2B2B",
                    position: "center",
                    confirmButtonColor:"rgb(0, 160, 223)",
                    title: "Eliminación Cancelada",
                    text: "No se ha eliminado la categoria seleccionada",
                });
            }
        });
    }

    // Llama a cargarUsuarios cuando el componente se monta
    useEffect(() => {
        cargarCategoria();
    }, []);
    return (
        <div>
            <button className='btnNuevoArt' onClick={mostrarFormularioAgregar}><FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />Nueva Categoria</button>
            <h2>Lista de categorias</h2>
            <table className='table table-dark table-hover table-responsive table-striped'>
                <thead>
                    <tr>
                        <th className='thCont anchoLargo'>Nombre</th>
                        <th className='thCont'>Cambiar Nombre</th>
                        <th className='thCont'>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {categoria.map(categoria => (
                        <tr key={categoria.id}>
                            <td className='tdCont'>{categoria.nombre}</td>
                            <td className='tdCont'>
                                <FontAwesomeIcon icon={faEdit} type='button' onClick={() => mostrarFormulario(categoria.id, categoria.nombre)} />
                            </td>
                            <td className='tdCont'>
                                <FontAwesomeIcon
                                    type='button'
                                    className='btnEliminar'
                                    icon={faX}
                                    onClick={() => mostrarAlerta(categoria.id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CategoriList
