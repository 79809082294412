import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';


const ListaUsuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const usuariosDb = collection(db, 'usuarios');


    // Función para cargar los usuarios desde la base de datos
    const cargarUsuarios = async () => {
        const querySnapshot = await getDocs(usuariosDb);
        const usuariosArray = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            // Comparar el nivel de usuario para determinar si es admin o vendedor
            let tipoUsuario;
            if (data.nivelUsuario === 'admin') {
                tipoUsuario = 'Admin';
            } else if (data.nivelUsuario === 'vendedor') {
                tipoUsuario = 'Vendedor';
            } else {
                tipoUsuario = 'Desconocido';
            }
            usuariosArray.push({
                id: doc.id,
                email: data.email,
                nivelUsuario: tipoUsuario
            });
        });
        setUsuarios(usuariosArray);
    }

    // Función para cambiar el nivel de usuario
    const cambiarNivelUsuario = (userId, nuevoNivel) => {
        const usuarioRef = doc(usuariosDb, userId); // Referencia al documento del usuario específico
        updateDoc(usuarioRef, {
            nivelUsuario: nuevoNivel
        })
            .then(() => {
                console.log("Nivel de usuario actualizado correctamente.");
                cargarUsuarios(); // Recargar la lista de usuarios después de actualizar
            })
            .catch(error => {
                console.error("Error al actualizar nivel de usuario:", error.message);
            });
    }

    // Llama a cargarUsuarios cuando el componente se monta
    useEffect(() => {
        cargarUsuarios();
    }, []);
    return (
        <div>
            <h2>Lista de Usuarios</h2>
            <table className='table table-dark table-hover table-responsive table-striped'>
                <thead>
                    <tr>
                        <th className='thCont'>Email</th>
                        <th className='thCont'>Nivel de Usuario</th>
                        <th className='thCont'>Cambiar Rol</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map(usuario => (
                        <tr key={usuario.id}>
                            <td className='tdCont'>{usuario.email}</td>
                            <td className='tdCont'>{usuario.nivelUsuario}</td>
                            <td className='tdCont'>
                                <select className='selectTipoRol' value={usuario.nivelUsuario} onChange={e => cambiarNivelUsuario(usuario.id, e.target.value)}>
                                    <option value="">-------</option>
                                    <option selected={usuario.nivelUsuario === "Vendedor"} value="vendedor">Vendedor</option>
                                    <option selected={usuario.nivelUsuario === "Admin"} value="admin">Admin</option>
                                </select>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ListaUsuarios
