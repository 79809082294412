import React, { useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import { db } from '../firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { faEdit, faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FirebaseDataFetcher from './Llamada/FirebaseDataFetcher';

const CategoMarca = () => {
    const [categoria, setCategoria] = useState([]);
    const [newImages, setNewImages] = useState([]);

    const categoriaDb = FirebaseDataFetcher({collectionName:'categoMarca'});

    const cargarCategoria = async () => {
        const querySnapshot = await getDocs(categoriaDb);
        const categoriaArray = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            categoriaArray.push({
                id: doc.id,
                nombre: data.nombre,
                imagen: data.imagen || '', // Si la imagen no está definida, se asigna un valor vacío
            });
        });
        setCategoria(categoriaArray);
    }
    const mostrarFormulario = (id, nombreActual, imagenActual) => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor:"rgb(0, 160, 223)",
            title: 'Actualizar Nombre e Imagen',
            html:
                `<input id="nombre" class="swal2-input" value="${nombreActual}">` +
                `<input type="file" id="imagen" class="swal2-file" accept="image/*">`, // Agregar campo de entrada para la nueva imagen
            focusConfirm: false,
            preConfirm: () => {
                const nuevoNombre = Swal.getPopup().querySelector('#nombre').value;
                const nuevaImagen = Swal.getPopup().querySelector('#imagen').files[0]; // Obtener la nueva imagen seleccionada
                return { nuevoNombre: nuevoNombre, nuevaImagen: nuevaImagen };
            }
        }).then(result => {
            if (result.isConfirmed) {
                const { nuevoNombre, nuevaImagen } = result.value;
                if (nuevaImagen) {
                    // Si se selecciona una nueva imagen, leerla como URL de datos
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        cambiarNivelCategoria(id, nuevoNombre, e.target.result); // Actualizar la categoría con la nueva imagen
                    };
                    reader.readAsDataURL(nuevaImagen);
                } else {
                    // Si no se selecciona una nueva imagen, solo actualizar el nombre
                    cambiarNivelCategoria(id, nuevoNombre, imagenActual);
                }
            }
        });
    }
    
    const cambiarNivelCategoria = (id, nuevoNombre, nuevaImagen) => {
        const categoriaRef = doc(categoriaDb, id);
        updateDoc(categoriaRef, {
            nombre: nuevoNombre,
            imagen: nuevaImagen, // Incluir la nueva imagen en la actualización
        })
            .then(() => {
                console.log("Categoría actualizada correctamente.");
                cargarCategoria();
            })
            .catch(error => {
                console.error("Error al actualizar categoría:", error.message);
            });
    }

    const addCatego = async (name, image, images) => {
        try {
            await addDoc(categoriaDb, {
                nombre: name,
                imagen: image, // Almacenar la URL de la imagen en la base de datos
                images: images,
            }).then(() => {
                cargarCategoria();
            });

            console.log('Catego added successfully');
        } catch (e) {
            console.error('Error adding Catego:', e);
        }
    }

    const mostrarFormularioAgregar = () => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor:"rgb(0, 160, 223)",
            title: 'Agregar Nueva Categoría',
            html:
                `<input id="nombre" class="swal2-input" placeholder="Nombre de la categoría">` +
                `<input type="file" id="imagen" class="swal2-file" accept="image/*">`, // Agregar un campo de entrada para la imagen
            focusConfirm: false,
            preConfirm: () => {
                const nuevoNombre = Swal.getPopup().querySelector('#nombre').value;
                const imagen = Swal.getPopup().querySelector('#imagen').files[0]; // Obtener la imagen seleccionada
                if (!nuevoNombre || !imagen) {
                    Swal.showValidationMessage('Por favor ingrese un nombre y seleccione una imagen para la categoría');
                }
                return { nuevoNombre: nuevoNombre, imagen: imagen };
            }
        }).then(result => {
            if (result.isConfirmed) {
                const { nuevoNombre, imagen } = result.value;
                const reader = new FileReader();
                reader.onload = (e) => {
                    addCatego(nuevoNombre, e.target.result, newImages); // Agregar la imagen al almacenar la categoría
                };
                reader.readAsDataURL(imagen); // Convertir la imagen a una URL de datos
            }
        });
    }

    const handleDeleteCatego = async (categoId) => {
        try {
            await deleteDoc(doc(db, 'categoMarca', categoId));
            console.log('Producto eliminado con ID:', categoId);
            await cargarCategoria();

        } catch (error) {
            console.error('Error al eliminar producto:', error);
        }
    }

    const mostrarAlerta = (categoId) => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor:"rgb(0, 160, 223)",
            title: "Estás seguro de que quieres eliminar esta Linea?",
            showDenyButton: true,
            confirmButtonText: "Confirmar",
            denyButtonText: `Cancelar`
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteCatego(categoId);
            } else if (result.isDenied) {
                Swal.fire({
                    width: "25em",
                    color: "#fff",
                    background: "#2B2B2B",
                    position: "center",
                    confirmButtonColor:"rgb(0, 160, 223)",
                    title: "Eliminación Cancelada",
                    text: "No se ha eliminado la Linea seleccionada",
                });
            }
        });
    }

    useEffect(() => {
        cargarCategoria();
    }, []);

    return (
        <div>
            <button className='btnNuevoArt' onClick={mostrarFormularioAgregar}>
                <FontAwesomeIcon icon={faPlus} style={{ paddingRight: "5px" }} />
                Nueva Linea
            </button>
            <h2>Lista de Lineas</h2>
            <table className='table table-dark table-hover table-responsive table-striped'>
                <thead>
                    <tr>
                        <th className='thCont anchoLargo'>Nombre</th>
                        <th className='thCont'>Imagen</th> {/* Agregar una columna para la imagen */}
                        <th className='thCont'>Cambiar Nombre</th>
                        <th className='thCont'>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {categoria.map(categoria => (
                        <tr key={categoria.id}>
                            <td className='tdCont'>{categoria.nombre}</td>
                            <td className='tdCont'>
                                {categoria.imagen && <img src={categoria.imagen} alt={`Imagen de ${categoria.nombre}`} style={{ width: '100px', marginRight: '10px' }} />}
                            </td>
                            <td className='tdCont'>
                                <FontAwesomeIcon icon={faEdit} type='button' onClick={() => mostrarFormulario(categoria.id, categoria.nombre)} />
                            </td>
                            <td className='tdCont'>
                                <FontAwesomeIcon
                                    type='button'
                                    className='btnEliminar'
                                    icon={faX}
                                    onClick={() => mostrarAlerta(categoria.id)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CategoMarca;
