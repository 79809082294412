import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { deleteDoc, doc } from 'firebase/firestore';
import ProductForm from './ProductForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSortDown, faSortUp, faStore, faX } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import FirebaseDataFetcher from './Llamada/FirebaseDataFetcher';


const ProductList = ({ handleEditProduct }) => {
    const [products, setProducts] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');

    const productosData = FirebaseDataFetcher({collectionName:'productos'})


    const handleDeleteProduct = async (productId) => {
        try {
            await deleteDoc(doc(db, 'productos', productId));
            console.log('Producto eliminado con ID:', productId);
            const updatedProducts = await productosData;
            setProducts(updatedProducts);
        } catch (error) {
            console.error('Error al eliminar producto:', error);
        }
    }

    const mostrarAlerta = (productId) => {
        Swal.fire({
            width: "25em",
            color: "#fff",
            background: "#2B2B2B",
            position: "center",
            confirmButtonColor: "rgb(0, 160, 223)",
            title: "Estás seguro de que quieres eliminar este producto?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
            denyButtonText: `Cancelar`
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteProduct(productId);
            } else if (result.isDenied) {
                Swal.fire({
                    width: "25em",
                    color: "#fff",
                    background: "#2B2B2B",
                    position: "center",
                    confirmButtonColor: "rgb(0, 160, 223)",
                    title: "Eliminación Cancelada",
                    text: "No se ha eliminado el producto seleccionado",
                });
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await productosData;
            setProducts(data);
        };

        fetchData();
    }, []);

    const handleEdit = async (productId) => {
        console.log(productId);
        handleEditProduct(productId)
    }

    const compareProducts = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        let comparison = 0;
        if (nameA > nameB) {
            comparison = 1;
        } else if (nameA < nameB) {
            comparison = -1;
        }
        return sortOrder === 'asc' ? comparison : comparison * -1;
    }

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    const sortedProducts = products.sort(compareProducts)

    return (
        <div>
            <button className='btnNuevoArt' onClick={() => handleEditProduct(<ProductForm />)}><FontAwesomeIcon icon={faStore} style={{ paddingRight: "5px" }} />Nuevo Producto</button>
            <h2 style={{ paddingTop: "20px" }}>Lista de Productos</h2>
            <table className='table table-dark table-hover table-responsive table-striped'>
                <thead >
                    <tr>
                        <th className='thCont'>Nombre
                            <button className="sort-btn" onClick={toggleSortOrder}>
                                {sortOrder === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />}
                            </button>
                        </th>
                        <th className='thCont'>Imágenes</th>
                        <th className='thCont'>Precio Unitario</th>
                        <th className='thCont'>Stock Disponible</th>
                        <th className='thCont'>Categoría</th>
                        <th className='thCont'>Linea</th>
                        <th className='thCont'>Estado</th>
                        <th className='thCont'>Oferta</th>
                        <th className='thCont'></th>
                        <th className='thCont'></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedProducts.map((product) => (
                        <tr key={product.id}>
                            <td className='tdCont'>{product.name}</td>
                            <td className='tdCont'>
                                {product.images.map((image, index) => (
                                    <img key={index} src={image} style={{ width: '50px' }} alt={`Product ${index}`} />
                                ))}
                            </td>
                            <td className='tdCont'>{new Intl.NumberFormat("es-AR", {
                                style: "currency",
                                currency: "ARS",
                            }).format(product.precio_uni)}</td>
                            <td className='tdCont'>{product.stk_disp}</td>
                            <td className='tdCont'>{product.categoria}</td>
                            <td className='tdCont'>{product.categoMarca}</td>
                            <td className='tdCont'>{product.id_estado}</td>
                            <td className='tdCont'>{product.oferta}</td>
                            <td className='tdCont'>
                                <FontAwesomeIcon type='button' className='btnEdit' icon={faPenToSquare} onClick={() => handleEdit(product.id)}></FontAwesomeIcon>
                            </td>
                            <td className='tdCont'>
                                <FontAwesomeIcon
                                    type='button'
                                    className='btnEliminar'
                                    icon={faX}
                                    onClick={() => mostrarAlerta(product.id)}
                                ></FontAwesomeIcon>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div >
    );
};

export default ProductList;
