import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import FirebaseDataFetcher from './Llamada/FirebaseDataFetcher';

const ProductForm = ({ handleSubMenuItemForm }) => {

    const [formData, setFormData] = useState({
        name: '',
        precio_uni: '',
        stk_disp: '',
        categoria: '',
        categoMarca: '',
        id_estado: '',
        ofertaNum: '',
        oferta: '',
        descripcion: '',
    });

    const [images, setImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [categoMarca, setCategoMarca] = useState([]);
    const [estadoProd, setEstadoProd] = useState([]);
    const [ofertaProd, setOfertaProd] = useState([]);

    const categoriasCollectionRef = FirebaseDataFetcher({collectionName: 'categorias'});
    const estadoCollectionRef = FirebaseDataFetcher({collectionName: 'estadoProduct'});
    const ofertaCollectionRef = FirebaseDataFetcher({collectionName: 'oferta'});
    const categoMarcaCollectionRef = FirebaseDataFetcher({collectionName: 'categoMarca'});

    const cargarCategorias =  async () => {
        try {
            const categoArray = [];

            categoriasCollectionRef.forEach(doc => {
                const data = doc.data();

                categoArray.push({
                    id: doc.id,
                    nombre: data.nombre // Acceder al campo 'nombre' correctamente
                });
            });
            setCategorias(categoArray);
        } catch (error) {
            console.error("Error al cargar categorías:", error);
        }
    }

    const cargarCategoMarca = async () => {
        try {
            const categoMarcaArray = [];

            categoMarcaCollectionRef.forEach(doc => {
                const data = doc.data();

                categoMarcaArray.push({
                    id: doc.id,
                    nombre: data.nombre // Acceder al campo 'nombre' correctamente
                });
            });
            setCategoMarca(categoMarcaArray);
        } catch (error) {
            console.error("Error al cargar categorías:", error);
        }
    }

    const cargarEstados = async () => {
        try {
            const estadoArray = [];

            estadoCollectionRef.forEach(doc => {
                const data = doc.data();

                estadoArray.push({
                    id: doc.id,
                    tipo: data.tipo,
                });
            });
            setEstadoProd(estadoArray);
        } catch (error) {
            console.error("Error al cargar categorías:", error);
        }
    }

    const cargarOferta = async () => {
        try {
            const ofertaArray = [];

            ofertaCollectionRef.forEach(doc => {
                const data = doc.data();
                const tipoCapitalizado = data.tipo.charAt(0).toUpperCase() + data.tipo.slice(1);

                ofertaArray.push({
                    id: doc.id,
                    tipo: tipoCapitalizado,
                });
            });
            setOfertaProd(ofertaArray);
        } catch (error) {
            console.error("Error al cargar categorías:", error);
        }
    }

    useEffect(() => {
        cargarEstados();
        cargarCategorias();
        cargarOferta();
        cargarCategoMarca();
    }, [])


    const handleSaveProduct = async () => {
        try {
            if (editingProductId) {
                // Si estás editando un producto existente, actualiza los datos del producto en la base de datos
                const productDoc = doc(db, 'productos', editingProductId);

                // Elimina las imágenes que se han eliminado en el formulario
                const updatedImages = images.map((image) => image.url);
                await updateDoc(productDoc, {
                    name: formData.name,
                    precio_uni: formData.precio_uni,
                    stk_disp: formData.stk_disp,
                    categoria: formData.categoria,
                    categoMarca: formData.categoMarca,
                    id_estado: formData.id_estado,
                    oferta: formData.oferta,
                    ofertaNum: formData.ofertaNum,
                    descripcion: formData.descripcion,
                    images: updatedImages,
                });

                console.log('Producto editado con éxito');
            } else {
                // Si estás agregando un nuevo producto, guarda las imágenes en el estado local
                const uniqueImages = [...new Set(imageFiles)];
                const imageUrls = await Promise.all(uniqueImages.map(async (file) => {
                    const imageData = await getBase64(file);
                    return imageData;
                }));

                // Agrega el nuevo producto con las imágenes a la base de datos
                const productsCollection = collection(db, 'productos');
                await addDoc(productsCollection, {
                    name: formData.name,
                    precio_uni: formData.precio_uni,
                    stk_disp: formData.stk_disp,
                    categoria: formData.categoria,
                    categoMarca: formData.categoMarca,
                    id_estado: formData.id_estado,
                    oferta: formData.oferta,
                    ofertaNum: formData.ofertaNum,
                    descripcion: formData.descripcion,
                    images: imageUrls,
                });

                console.log('Producto agregado con éxito');
            }

            // Limpiar el formulario y restablecer el estado después de agregar/editar
            setFormData({
                name: '',
                precio_uni: '',
                stk_disp: '',
                categoria: '',
                categoMarca: '',
                id_estado: '',
                oferta: '',
                descripcion: '',
            });
            setImageFiles([]);
            setImages([]);

            // Navegar de vuelta a la lista de productos
            handleSubMenuItemForm();
        } catch (error) {
            console.error('Error al guardar producto:', error);
        }
    }


    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);

        if (files.length + images.length > 4) {
            alert("Solo se permiten hasta 4 imágenes.");
            return;
        }

        const newImages = files.map((file) => ({ url: URL.createObjectURL(file), file }));

        // Actualizar imágenes solo con imágenes únicas
        setImages((prevImages) => [...prevImages, ...newImages]);
        setImageFiles((prevFiles) => [...prevFiles, ...files]);
    }

    const handleRemoveImage = (index) => {
        // Copiar el array de imágenes actual y eliminar la imagen en el índice especificado
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);

        // También debes eliminar el archivo correspondiente del array de archivos
        const updatedFiles = [...imageFiles];
        updatedFiles.splice(index, 1);
        setImageFiles(updatedFiles);
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const handleCategoriaChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            categoria: value,
        });
    }

    const handleCategoMarcaChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            categoMarca: value,
        });
    }

    const handleEstadoChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            id_estado: value,
        });
    }

    const handleOfertaChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            oferta: value,
        });
    }

    useEffect(() => {
        let editarProduct = localStorage.getItem("productId");
        // Si estamos en modo de edición, cargar los datos del producto seleccionado
        if (editarProduct !== null && editarProduct !== undefined && editarProduct !== "") {
            setEditingProductId(editarProduct);
            const fetchProductData = async () => {
                const productDoc = await getDoc(doc(db, 'productos', editarProduct));

                // Verificar si productDoc y productData no son undefined
                if (productDoc.exists()) {
                    const productData = productDoc.data();
                    if (productData) {
                        setFormData({
                            name: productData.name || '',
                            precio_uni: productData.precio_uni || '',
                            stk_disp: productData.stk_disp || '',
                            categoria: productData.categoria || '',
                            categoMarca: productData.categoMarca || '',
                            id_estado: productData.id_estado || '',
                            oferta: productData.oferta || '',
                            ofertaNum: productData.ofertaNum || '',
                            descripcion: productData.descripcion || '',
                        });

                        // Previsualizar imágenes existentes
                        const existingImages = productData.images || [];
                        setImages(existingImages.map((imageUrl) => ({ url: imageUrl })));
                    }
                } else {
                    console.log('No se encontró el documento del producto');
                }
            };

            fetchProductData();
        }
    }, []);


    return (
        <div>
            <h2 style={{ textAlign: 'center' }}>{editingProductId ? 'Editar Producto' : 'Agregar Producto'}</h2>
            <form>
                <div className='row contFormProduct'>
                    <div className="col-12">
                        <label>Nombre del Producto:</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nombre del Producto"
                            value={formData.name}
                            onChange={handleInputChange}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label>Precio del Producto:</label>
                        <input
                            type="number"
                            name="precio_uni"
                            placeholder="Precio del Producto (usar coma en decimal)"
                            value={formData.precio_uni}
                            onChange={handleInputChange}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label>Stock disponible:</label>
                        <input
                            type="number"
                            name="stk_disp"
                            placeholder="Stock disponible"
                            value={formData.stk_disp}
                            onChange={handleInputChange}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <label>Categoria:</label>
                        <select
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                            value={formData.categoria}
                            onChange={handleCategoriaChange}>
                            <option value="" disabled>Selecciona una categoría</option>
                            {categorias.map((catego) => (
                                <option key={catego.id} value={catego.nombre}>{catego.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 col-12">
                        <label>Linea:</label>
                        <select
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                            value={formData.categoMarca}
                            onChange={handleCategoMarcaChange}>
                            <option value="" disabled>Selecciona una Linea</option>
                            {categoMarca.map((catego) => (
                                <option key={catego.id} value={catego.nombre}>{catego.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6 col-12">
                        <label>Estado:</label>
                        <select
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                            value={formData.id_estado}
                            onChange={handleEstadoChange}>
                            <option value="" disabled>Selecciona un estado</option>
                            {estadoProd.map((estad) => (
                                <option key={estad.id} value={estad.tipo}>{estad.tipo}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <label>Descripción del Producto:</label>
                        <textarea
                            name="descripcion"
                            placeholder="Descripción del Producto"
                            value={formData.descripcion}
                            onChange={handleInputChange}
                            style={{ width: '100%', height: '100px', resize: 'vertical', marginBottom: '10px', padding: '5px' }}
                        />
                    </div>
                    <div className="col-12">
                        <label>Oferta:</label><br />
                        <input
                            type="number"
                            name="ofertaNum"
                            placeholder="Porcentaje solo numero"
                            value={formData.ofertaNum}
                            onChange={handleInputChange}
                            style={{ width: '50%', marginBottom: '10px', padding: '5px' }}
                        />
                        <select
                            style={{ width: '50%', marginBottom: '10px', padding: '5px' }}
                            value={formData.oferta}
                            onChange={handleOfertaChange}>
                            <option value="" disabled>Selecciona estado oferta</option>
                            {ofertaProd.map((estad) => (
                                <option key={estad.id} value={estad.tipo}>{estad.tipo}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <label>Imágenes:</label>
                        <br />
                        <input className='inputFile' type="file" onChange={handleImageChange} multiple style={{ marginBottom: '20px' }} />

                        {images.map((image, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <img src={image.url} alt={`Imagen ${index + 1}`} style={{ width: '100px', marginRight: '10px' }} />
                                <button type="button" onClick={() => handleRemoveImage(index)}>
                                    Eliminar
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className='col-md-6 col-12'>
                        <button type="button" onClick={handleSaveProduct} style={{
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            padding: '10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '100%',
                            marginRight: '10px', // Añade un margen derecho para separar el botón de volver
                        }}>
                            {editingProductId ? 'Guardar Cambios' : 'Agregar Producto'}
                        </button>
                    </div>
                    <div className='col-md-6 col-12'>
                        <button type="button" onClick={() => handleSubMenuItemForm()} style={{
                            backgroundColor: '#337ab7', // Puedes ajustar el color según tu estilo
                            color: 'white',
                            padding: '10px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            width: '100%',
                        }}>
                            Volver
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ProductForm;

