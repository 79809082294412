// Layout.js
import React, { useState } from 'react';
import SidebarMenu from './SidebarMenu';
import ProductList from './ProductList';
import ProductForm from './ProductForm';
import Home from './Home';
import Registro from './Login/Registro';
import { useNavigate } from 'react-router-dom';
import ListaUsuarios from './ListaUsuarios';
import CategoriList from './ListaCategorias';
import CategoMarca from './ListaCategoMarca';

const Layout = ({ isAuthenticated }) => {
    const navigate = useNavigate();
    const handleSubMenuItemClickEdit = (productId) => {
        console.log(productId)
        if (productId !== null && productId !== undefined && productId.key !== null) {
            setEditProductId(productId); // Almacena solo el ID del producto
            localStorage.setItem("productId", productId); // Almacena solo el ID del producto
            activarForm(productId);
        } else {
            activarForm();
            localStorage.setItem("productId", ""); // Almacena una cadena vacía
            setEditProductId(null);
        }
    }

    const [activeComponent, setActiveComponent] = useState(<ProductList handleEditProduct={handleSubMenuItemClickEdit} />);
    const [editProductId, setEditProductId] = useState(null);

    const activarForm = ()=>{
        setActiveComponent(<ProductForm handleSubMenuItemForm={handleSubMenuItemForm}/>)
    }    

    const handleSubMenuItemClick = (component) => {
        setActiveComponent(component);
        setEditProductId(null);
        localStorage.setItem("productId", "");
    }

    const handleSubMenuItemForm = () => {
        localStorage.setItem("productId", ""); // Almacena una cadena vacía
        setActiveComponent(<ProductList handleEditProduct={handleSubMenuItemClickEdit} />);
    }


    const menuItems = [
        {
            title: 'Gestión de Productos',
            subItems: [
                { text: 'Lista de Productos', component: <ProductList handleEditProduct={handleSubMenuItemClickEdit} /> },
                { text: 'Agregar Producto', component: <ProductForm handleSubMenuItemForm ={handleSubMenuItemForm} /> },
            ]
        },{
            title: 'Gestión Categorias - Lineas',
            subItems: [
                { text: 'Lista de Categorias', component: <CategoriList /> },
                { text: 'Lista de Lineas', component: <CategoMarca /> },
            ]
        },
        {
            title: 'Registro',
            subItems: [
                { text: 'Registro de Usuarios', component: <Registro /> },
                { text: 'Lista de Usuarios', component: <ListaUsuarios /> },
            ]
        },
    ];
    

    if (!isAuthenticated()) {
        navigate('/');
        return null;
    }

    return (
        <div className="layout">
            <SidebarMenu menuItems={menuItems} handleSubMenuItemClick={handleSubMenuItemClick} />
            <div className="content">
                {activeComponent}
            </div>
        </div>
    );
};

export default Layout;
