import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function MenuItem({ icon, isActive, subItems, title, onItemClick }) {
    const [isOpen, setIsOpen] = useState(isActive);

    const handleClick =  () => {
        setIsOpen(!isOpen);
    }

    return (
        <li className={`${isActive ? 'active' : ''} ${isOpen ? 'menu-active' : ''}`}>
            <a href="#1" onClick={handleClick}>
                <i className={icon}></i><span>&nbsp;&nbsp;{title}</span>
            </a>
            {subItems && (
                <ul className={`treeview-menu ${isActive && isOpen ? 'submenu-active' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}>
                    {subItems.map((subItem, subIndex) => (
                        <SubMenuItem key={subIndex} {...subItem} onItemClick={() => onItemClick(subItem.component)} />
                    ))}
                </ul>
            )}
        </li>
    );
}

function SubMenuItem({ icon, text, onItemClick }) {
    const handleClick = () => {
        onItemClick();
    }

    return (
        <li style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <a href="#2" onClick={handleClick}>
                <i className={icon}></i><span>&nbsp;&nbsp;{text}</span>
            </a>
        </li>
    );
}

function SidebarMenu({ menuItems, handleSubMenuItemClick }) {
    const [isHamburgerMenu, setIsHamburgerMenu] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleResize =  () => {
        setIsHamburgerMenu(window.innerWidth <= 592); // 37rem is approximately equal to 592px
    }

    useEffect(() => {
        handleResize(); // Check initial width
        window.addEventListener('resize', handleResize); // Listen for window resize
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up resize listener
        };
    }, [handleResize]);

    // Función para abrir/cerrar el menú en modo hamburguesa
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    // Función para cerrar sesión
    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log("Usuario ha cerrado sesión");
            })
            .catch((error) => {
                console.error("Error al cerrar sesión:", error);
            });
    }

    return (
        <aside className={`main-sidebar ${isHamburgerMenu ? 'sidebar-menu-hamburger' : ''}`}>
            <section className="contLogo">
                <img src={require('../assets/efit.png')} alt='logo' style={{ width: "150px" }} />
            </section>
            <section className="sidebar">
                <ul className={`sidebar-menu ${isMenuOpen && isHamburgerMenu ? 'open' : ''}`}>
                    {menuItems.map((menuItem, index) => (
                        <MenuItem key={index} {...menuItem} onItemClick={handleSubMenuItemClick} />
                    ))}
                </ul>
            </section>
            <section className='contButton'>
                <button onClick={handleLogout}>Cerrar Sesión</button> {/* Botón para cerrar sesión */}
            </section>
            {isHamburgerMenu && (
                <FontAwesomeIcon icon={faBars} className="hamburger-menu-icon" onClick={toggleMenu}>
                    <div className={`line ${isMenuOpen ? 'line1-open' : ''}`}></div>
                    <div className={`line ${isMenuOpen ? 'line2-open' : ''}`}></div>
                    <div className={`line ${isMenuOpen ? 'line3-open' : ''}`}></div>
                </FontAwesomeIcon>
            )}
        </aside>
    );
}

export default SidebarMenu;