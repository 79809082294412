import React, { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const handleLogin = useCallback((e) => {
        e.preventDefault(); // Evita que el formulario se envíe por defecto
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setError(null);
                console.log("Usuario ha iniciado sesión:", userCredential.user);
                navigate('/Inicio')
            })
            .catch((error) => {
                setError(error.message);
                console.error("Error al iniciar sesión:", error);
            });
    });

    return (
        <div className='contenedorLogin'>
            <div className='login'>
                <form className='formularioLogin' onSubmit={handleLogin}>
                    <h1 className='tituloLogin'>Iniciar Sesión</h1>
                    <label className='labelLogin'>Usuario</label>
                    <input className='inputLogin' type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <label className='labelLogin'>Contraseña</label>
                    <input className='inputLogin' type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                    {error && <p>{error}</p>}
                    <button type="submit" className='btnLogin'>Iniciar Sesión</button>
                </form>
            </div>
        </div>
    );
};

export default Login;

